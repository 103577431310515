import * as contracts from "./contracts";

export const CHAINS = {
  ARBITRUM: 42161
};

export const ARBITRUM_GRAPHQL_URL = "https://subgraph.satsuma-prod.com/f8dfbcd260e8/cryptex--personal--24138/cryptex-arbitrum/api";

export const ERROR_USER_REJECTED_TRAN = "UserRejectedRequestError";

export enum ApproveType {
  DEPOSIT,
  WITHDRAW,
  CLAIM,
  STAKE,
}

export const TOKENS_SYMBOLS = {
  tcap: "tcap",
};

export const WIKI_TCAP_URL = "https://cryptexfinance.notion.site/TCAP-Total-Crypto-Market-Cap-b87f0d5331a24b0ba6796f579fe531af";

export const API_BASE_URL = {
  [CHAINS.ARBITRUM]: {
    url: "https://rebate-program.herokuapp.com/"
  }
};

export const USDC_TOKEN = {
  key: "usdc",
  symbol: "USDC",
  description: "USDC",
  displayDecimals: 4,
  multiplier: 1n,
  [CHAINS.ARBITRUM]: {
    oracleContract: contracts.usdcOracleContract,
  }
};

export const TCAP_TOKEN = {
  key: TOKENS_SYMBOLS.tcap,
  symbol: TOKENS_SYMBOLS.tcap.toUpperCase(),
  description: "Total Crypto Market Cap",
  displayDecimals: 4,
  multiplier: 1n,
  [CHAINS.ARBITRUM]: {
    oracleContract: contracts.tcapOracleContract,
  }
};

// Markets
export const TCAP_MARKET = {
  market: TCAP_TOKEN,
  collateral: USDC_TOKEN,
  longContract: contracts.tcapLongMarketContract,
  shortContract: contracts.tcapShortMarketContract,
  collateralContract: contracts.productCollateralContract,
  vaultContract: contracts.tcapVaultContract,
};


export const MARKETS = {
  [TOKENS_SYMBOLS.tcap]: TCAP_MARKET,
}

export const PRODUCTS_ADDRESS = {
  [CHAINS.ARBITRUM]: [
    TCAP_MARKET.shortContract[CHAINS.ARBITRUM].address,
    TCAP_MARKET.longContract[CHAINS.ARBITRUM].address,
  ]
}

export const aggregatorContracts = {
  [TOKENS_SYMBOLS.tcap]: {
    address: "0x7B9845A634822c543F5Ce544Dd7D7797B79a06b8"
  },
};

export const ACTION_TYPES = {
  deposit: "DEPOSIT",
  liquidated: "LIQUIDATED",
  makeClosed: "MAKE_CLOSED",
  makeOpened: "MAKE_OPENED",
  takeClosed: "TAKE_CLOSED",
  takeOpened: "TAKE_OPENED",
  withdraw: "WITHDRAW",
}

export const POSITION_STATUS = {
  closed: "closed",
  closing: "closing",
  liquidated: "liquidated",
  liquidable: "liquidable",
  liquidating: "liquidating",
  open: "open",
}